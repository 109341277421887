<template>
  <div class="container">
    <div class="newsList flex-box">
      <div class="newItem" v-for="(item, index) in newsList" :key="index" @click="clickToDetail(index)">
        <img class="pic" :src="item.pic" />
        <div class="title" v-html="language === 'cn' ? item.cnTitle : item.enTitle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { changeImageUrl } from '@/utils'
export default {
  name: 'news',
  components: {},
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  data() {
    return {
      newsList: []
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    getNewsList() {
      this.newsList = config.newsList.map((item) => {
        return {
          ...item,
          pic: changeImageUrl(item.pic, {
            width: 1400,
            height: 417,
            scaling: 'fill'
          })
        }
      })
    },
    clickToDetail(index) {
      this.$router.push({ path: '/news/detail', query: { id: index + 1 } })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-box {
  display: flex;
}
.newsList {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  .newItem {
    width: calc(50% - 6px);
    font-weight: 400;
    cursor: pointer;
    .pic {
      width: 100%;
      // height: 440px;
    }
    .title {
      font-size: 20px;
      line-height: 36px;
      text-align: center;
      padding: 5px 0 60px;
    }
  }
}

// @media screen and(max-width: 1450px) {
//   .newsList .newItem .title {
//     font-size: 26px;
//   }
// }
// @media screen and(max-width: 1350px) {
//   .newsList .newItem .title {
//     font-size: 24px;
//   }
// }

// @media screen and(max-width: 1240px) {
//   .newsList .newItem .title {
//     font-size: 22px;
//   }
// }

@media screen and(max-width: 1140px) {
  .newsList .newItem .title {
    font-size: 18px;
  }
}
</style>
